/**
 * @deprecated old palette, please use colors.ts
 */
export const palette = {
  white: '#FFFFFF',
  green: '#64B950',
  lightGreen: '#A4ED93',
  darkGreen: '#308821',
  cream: '#F4EDE7',
  darkCream: '#C1BBB5',
  grey: '#595959',
  midGrey: '#575750',
  lightGrey: '#ccc',
  lightMidGrey: '#C1BBB5',
  black: '#000000',
  orange: '#FFA726',
  lightOrange: '#FFD95B',
  darkOrange: '#C77800',
  danger: '#d75746',
  darkGray3: '#293742', // from blueprint js
  inputGrey: '#495057', // from react-bootstrap js
  borderGrey: '#ced4da', // from react-bootstrap js
  borderBlue: '#80bdff', // from react-bootstrap js
  shadow: 'rgba(0, 123, 255, 0.25)', // from react-bootstrap js
};
