import { useLocation } from '@reach/router';
import Img, { FixedObject } from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DiscordIcon from '../../assets/images/discord-icon.png';
import FacebookIcon from '../../assets/images/facebook-icon.png';
import InstagramIcon from '../../assets/images/instagram-icon.png';
import LinkedinIcon from '../../assets/images/linkedin-icon.png';
import TwitterIcon from '../../assets/images/twitter-icon.png';
import YoutubeIcon from '../../assets/images/youtube-icon.png';
import { showAppStoreButtons } from '../../constants/urls';
import { useAppStoreLinks } from '../../hooks/useAppStoreLinks';
import {
  AddressSection,
  CompanyInfoSection,
  ContactInfoSection,
  FooterContainer,
  FooterInnerContainer,
  FooterRow,
  SocialLink,
  SocialLinks,
} from './Footer.styled';

export type Props = {
  appleAppStoreButton?: FixedObject | null;
  appleAppStoreLink?: string | null;
  googlePlayStoreButton?: FixedObject | null;
  googlePlayStoreLink?: string | null;
  mobileWebAppButton?: FixedObject | null;
};

export const Footer: React.FC<Props> = ({
  appleAppStoreButton,
  appleAppStoreLink,
  googlePlayStoreButton,
  googlePlayStoreLink,
  mobileWebAppButton,
}) => {
  const location = useLocation();
  const [showingAppStoreButtons, setShowingAppStoreButtons] = useState<boolean>(false);

  // read url params to set utm params to pass onto mailchimp waiting list sign up call
  useEffect(() => {
    setShowingAppStoreButtons(showAppStoreButtons({ urlParams: location.search }));
  }, [location.search]);

  const { appleLink, googleLink, webAppLink } = useAppStoreLinks({
    appleAppStoreLink,
    googlePlayStoreLink,
    fallbackWebUtmCampaign: `marketingSite1-footer`,
  });

  return (
    <FooterContainer>
      <FooterInnerContainer>
        <AddressSection>
          <p>
            Upside Technologies Limited
            <br />
            33 Broadwick Street
            <br />
            London
            <br />
            W1F 0DQ
            <br />
          </p>
        </AddressSection>

        <ContactInfoSection>
          <FooterRow>
            <SocialLinks>
              <SocialLink
                href="https://www.linkedin.com/company/upside-technology/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinIcon} alt="Linked In" />
              </SocialLink>
              <SocialLink href="https://twitter.com/upside_tech/" target="_blank" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="Twitter" />
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/upsidetechnologies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={InstagramIcon} alt="Instagram" />
              </SocialLink>
              <SocialLink href="https://www.facebook.com/upsidetechnologies/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="Facebook" />
              </SocialLink>
              <SocialLink
                href="https://www.youtube.com/channel/UCrRnoWKOYLfQSwVS93RCD7Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={YoutubeIcon} alt="YouTube" />
              </SocialLink>
              <SocialLink href="https://discord.gg/xeEdNN3NGt" target="_blank" rel="noopener noreferrer">
                <img src={DiscordIcon} alt="Discord" />
              </SocialLink>
            </SocialLinks>
          </FooterRow>

          {showingAppStoreButtons && (
            <FooterRow>
              <AppButtonLink href={appleLink}>
                {appleAppStoreButton && <Img fixed={appleAppStoreButton} />}
              </AppButtonLink>
              <AppButtonLink href={googleLink}>
                {googlePlayStoreButton && <Img fixed={googlePlayStoreButton} />}
              </AppButtonLink>
              <AppButtonLink href={webAppLink}>
                {mobileWebAppButton && <Img fixed={mobileWebAppButton} />}
              </AppButtonLink>
            </FooterRow>
          )}
          <FooterRow>
            {/* <AddressSection>
              <p>Authorised and regulated by the Financial Conduct Authority (FCA)</p>
            </AddressSection> */}
          </FooterRow>
        </ContactInfoSection>

        <CompanyInfoSection>
          <p>
            Company number: 11228711 <br />
            Upside Technologies Limited © {new Date().getFullYear()}
          </p>
          <a href="https://upside.peoplehr.net/jobboard" target="_blank" rel="noopener noreferrer">
            Careers
          </a>
          <br />
          <a href="https://upsidetechnology.co/privacy-notice" target="_blank" rel="noopener noreferrer">
            Privacy Notice
          </a>
          <br />
          <a href="https://upsidetechnology.co/disclaimer" target="_blank" rel="noopener noreferrer">
            Disclaimer
          </a>
          <br />
          <a href="https://help.upsidetechnology.co/" target="_blank" rel="noopener noreferrer">
            Help Centre
          </a>
        </CompanyInfoSection>
      </FooterInnerContainer>
    </FooterContainer>
  );
};

const AppButtonLink = styled.a`
  display: block;

  div {
    margin-right: 5px;
  }
  &:last-of-type {
    div {
      margin-right: 0;
    }
  }
`;
