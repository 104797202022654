import { FixedObject, FluidObject } from 'gatsby-image';
import { DatoCmsFixed, DatoCmsFluid } from '../generated/graphqlTypes';
import { breakpoints } from '../styles/breakpoints';

/**
 * Fix the returned type on DatoCmsFluid to match gatsby-image fluid. Then can pass fluid into `<Img fluid={fluid}>`
 * TODO: fix DatoCMS `base64` and `tracedSVG` types to match base64 in gatsby-image FluidObject and be just string | undefined. No null.
 *
 * @param datoCmsFluid
 */
export const fixFluid = <T extends DatoCmsFluid>(datoCmsFluid?: T | null): FluidObject | null => {
  if (!datoCmsFluid) return null;
  return {
    ...datoCmsFluid,
    base64: datoCmsFluid.base64 || '',
    tracedSVG: datoCmsFluid.tracedSVG || '',
  };
};

/**
 * Fix the returned type on DatoCmsFluid to match gatsby-image fixed. Then can pass fixed into `<Img fixed={fixed}>`
 * TODO: fix DatoCMS `base64` and `tracedSVG` types to match base64 in gatsby-image FixedObject and be just string | undefined. No null.
 *
 * @param datoCmsFixed
 */
export const fixFixed = <T extends DatoCmsFixed>(datoCmsFixed?: T | null): FixedObject | null => {
  if (!datoCmsFixed) return null;
  return {
    ...datoCmsFixed,
    base64: datoCmsFixed.base64 || '',
    tracedSVG: datoCmsFixed.tracedSVG || '',
  };
};

/**
 * art direction: return mobile image source then desktop image source
 *
 * @param mobileImage
 * @param desktopImage
 * @param desktopBreakpoint
 */
export const artDirectionFluid = <T extends DatoCmsFluid>(
  mobileImage?: T | null,
  desktopImage?: T | null,
  desktopBreakpoint = breakpoints.desktop,
): FluidObject[] => {
  const ret: FluidObject[] = [];
  const fixedMobile = fixFluid(mobileImage);
  const fixedDesktop = fixFluid(desktopImage);
  if (fixedMobile) {
    ret.push(fixedMobile);
  }
  if (fixedDesktop) {
    ret.push({
      ...fixedDesktop,
      media: `(min-width: ${desktopBreakpoint})`,
    });
  }
  return ret;
};

/**
 * art direction: return mobile image source then desktop image source
 *
 * @param mobileImage
 * @param desktopImage
 * @param desktopBreakpoint
 */
export const artDirectionFixed = <T extends DatoCmsFixed>(
  mobileImage?: T | null,
  desktopImage?: T | null,
  desktopBreakpoint = breakpoints.desktop,
): FixedObject[] => {
  const ret: FixedObject[] = [];
  const fixedMobile = fixFixed(mobileImage);
  const fixedDesktop = fixFixed(desktopImage);
  if (fixedMobile) {
    ret.push(fixedMobile);
  }
  if (fixedDesktop) {
    ret.push({
      ...fixedDesktop,
      media: `(min-width: ${desktopBreakpoint})`,
    });
  }
  return ret;
};
