export type ClientGatsbyEnv = {
  GATSBY_APP_URL: string;
  MARKETING_SITE_URL: string;
  GATSBY_MOBILE_APP_URL: string;
  GATSBY_MAILCHIMP_URL: string;
  GATSBY_MAILCHIMP_USER_U: string;
  GATSBY_MAILCHIMP_WAITLIST_AUDIENCE_ID: string;
  GATSBY_MAILCHIMP_WAITLIST_EMAIL_FIELD_TAG: string;
  GATSBY_MAILCHIMP_WAITLIST_UTM_SOURCE_FIELD_TAG: string;
  GATSBY_MAILCHIMP_WAITLIST_UTM_MEDIUM_FIELD_TAG: string;
  GATSBY_MAILCHIMP_WAITLIST_UTM_CAMPAIGN_FIELD_TAG: string;
};

/**
 * Read .env (via process.env) and return typed object
 * of the client-only values available in browser. These are the ones starting with `GATSBY_*`
 */
export const getClientGatsbyEnv = (): ClientGatsbyEnv => {
  const env: ClientGatsbyEnv = {
    GATSBY_APP_URL: process.env.GATSBY_APP_URL || '',
    MARKETING_SITE_URL: process.env.MARKETING_SITE_URL || 'https://upsidetechnology.co',
    GATSBY_MOBILE_APP_URL: process.env.GATSBY_MOBILE_APP_URL || '',
    GATSBY_MAILCHIMP_URL: process.env.GATSBY_MAILCHIMP_URL || '',
    GATSBY_MAILCHIMP_USER_U: process.env.GATSBY_MAILCHIMP_USER_U || '',
    GATSBY_MAILCHIMP_WAITLIST_AUDIENCE_ID: process.env.GATSBY_MAILCHIMP_WAITLIST_AUDIENCE_ID || '',
    GATSBY_MAILCHIMP_WAITLIST_EMAIL_FIELD_TAG: process.env.GATSBY_MAILCHIMP_WAITLIST_EMAIL_FIELD_TAG || '',
    GATSBY_MAILCHIMP_WAITLIST_UTM_SOURCE_FIELD_TAG: process.env.GATSBY_MAILCHIMP_WAITLIST_UTM_SOURCE_FIELD_TAG || '',
    GATSBY_MAILCHIMP_WAITLIST_UTM_MEDIUM_FIELD_TAG: process.env.GATSBY_MAILCHIMP_WAITLIST_UTM_MEDIUM_FIELD_TAG || '',
    GATSBY_MAILCHIMP_WAITLIST_UTM_CAMPAIGN_FIELD_TAG:
      process.env.GATSBY_MAILCHIMP_WAITLIST_UTM_CAMPAIGN_FIELD_TAG || '',
  };

  // check for empty values
  for (const key in env) {
    if (!env[key as keyof ClientGatsbyEnv]) {
      console.error(`client-only .env key "${key}" is blank!`);
    }
  }
  return env;
};
