import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { palette } from '../../styles/palette';
import { NAV_BAR_HEIGHT } from '../../styles/variables';

export const NavBar = styled.header`
  align-items: center;
  background: ${palette.white};
  display: flex;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  height: ${NAV_BAR_HEIGHT}px;
  justify-content: space-between;
  right: 0;
  width: 100%;
  z-index: 9999;

  * {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
