import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import { Column } from './Column';
import { Row } from './Row';
import { SectionContainer } from './SectionContainer';
import { SectionContent } from './SectionContent';
import { SectionTitle } from './SectionTitle';

export type Props = {
  header: string | null;
  backgroundColour: string | null;
  imageOnRight?: boolean | null;
  image?: FluidObject | null;
};

/**
 * A home page or static page section.
 *
 * @param param0
 * @param param0.header
 * @param param0.backgroundColour
 * @param param0.children the body text
 * @param param0.variant
 * @param param0.image
 * @param param0.imageOnRight
 * @param param0.callToActionText
 * @param param0.callToActionLinkUrl
 */
export const Section: React.FC<Props> = ({ header, backgroundColour, children, image, imageOnRight }) => (
  <SectionContainer backgroundColor={backgroundColour ?? ''}>
    <SectionContent>
      <Row>
        <Column order={imageOnRight ? 2 : 1} addGap={imageOnRight ?? false}>
          <SectionTitle>{header}</SectionTitle>
          {children}
        </Column>
        {image && (
          <Column order={imageOnRight ? 2 : 0} addGap={!imageOnRight ?? false}>
            <Img fluid={image} />
          </Column>
        )}
      </Row>
    </SectionContent>
  </SectionContainer>
);
