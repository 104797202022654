import Typography from 'typography';

/** setup typography plugin to stop font flash */
const typographyPlugin = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.6,
  headerFontFamily: ['poppins-semibold', 'sans-serif'],
  bodyFontFamily: ['poppins-regular', 'sans-serif'],
  scaleRatio: 2, // default 2
});

// Export helper functions
export const { scale, rhythm, options } = typographyPlugin;

// default export for gatsby-plugin-typography
// eslint-disable-next-line import/no-default-export
export default typographyPlugin;
