import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { palette } from '../../styles/palette';

export const FooterContainer = styled.div`
  min-height: 250px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: ${palette.cream};

  @media screen and (min-width: ${breakpoints.desktop}) {
    font-size: 26px;
    min-height: 260px;
  }
`;
export const FooterInnerContainer = styled.div`
  display: flex;
  border-top: 2.5px solid #707070;
  padding-top: 16px;
  min-height: 345px;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;

  a:link,
  a:visited {
    color: ${palette.black};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    min-height: 145px;
    justify-content: space-between;
  }
`;
export const FooterLogo = styled.img`
  width: 100px;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-bottom: 5px;
  }
`;

export const AddressSection = styled.div`
  text-align: center;
  @media screen and (min-width: ${breakpoints.desktop}) {
    text-align: left;
  }
`;
export const ContactInfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialLinks = styled.div`
  justify-items: center;

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: 80px;
    width: 375px;
  }
`;

export const CompanyInfoSection = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.desktop}) {
    text-align: right;
  }
`;

export const SocialLink = styled.a`
  width: 35px;
  height: 35px;
  margin-right: 20px;
  display: inline-block;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-right: 32px;
  }

  &:last-of-type {
    /** no need for margin right on last social link so centres correctly */
    margin-right: 0;
  }
`;
