import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { getAppleAppStoreLink, getGooglePlayStoreLink, getMobileWebAppLink } from '../constants/urls';

type Args = {
  appleAppStoreLink?: string | null;
  googlePlayStoreLink?: string | null;
  fallbackWebUtmCampaign?: string | null;
};

/**
 * Returns correct link to app stores from this logic
 * 1. if URL params exist (eg `branch=xyz123&utm_source=marketing&utm_medium=twitter&utm_campaign=May2022` as used from ads), construct a branch link with that alias (uses `useLocation` which runs on client not server btw)
 * 2. else use the app store links from dato
 * 3. fallback to direct links
 *
 * Have to use this hook because `href` property on an anchor was NOT re-rendering in client browser even though code ran in browser.
 * Solution is to explicitly use `useState` and `useEffect` to force re-render on client.
 * Otherwise href stays as server rendered one and does change to use branch and utm query params as set in links from ads
 */
export const useAppStoreLinks = ({ appleAppStoreLink, googlePlayStoreLink, fallbackWebUtmCampaign }: Args) => {
  const location = useLocation();
  const [appleLink, setAppleLink] = useState('');
  const [googleLink, setGoogleLink] = useState('');
  const [webAppLink, setWebAppLink] = useState('');

  // has to be 3 separate useEffects to trigger re-renders for all 3 for some reason
  useEffect(() => {
    setAppleLink(getAppleAppStoreLink({ urlParams: location.search, linkUrl: appleAppStoreLink }));
  }, [appleAppStoreLink, location.search]);

  useEffect(() => {
    setGoogleLink(getGooglePlayStoreLink({ urlParams: location.search, linkUrl: googlePlayStoreLink }));
  }, [googlePlayStoreLink, location.search]);

  useEffect(() => {
    setWebAppLink(getMobileWebAppLink({ urlParams: location.search, fallbackUtmCampaign: fallbackWebUtmCampaign }));
  }, [fallbackWebUtmCampaign, location.search]);

  return { appleLink, googleLink, webAppLink };
};
