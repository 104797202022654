import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import typographyPlugin from '../../styles/typographyPlugin';

/**
 * add gap between this Col and next
 *
 * @param root0
 * @param root0.addGap addGap add gap between this Col and next
 * @param root0.order
 */
export const Column = styled.div<{ order?: number; addGap?: boolean }>`
  display: block;

  /** on mobile columns are below each other */
  flex-direction: row;
  flex-basis: 100%;

  /** add margin spaces below columns on mobile, but on last one want none */
  margin-bottom: ${typographyPlugin.options.baseLineHeight}rem;

  /* on desktop columns are side-by-side */
  @media screen and (min-width: ${breakpoints.desktop}) {
    order: ${({ order }) => order};
    flex-direction: column;
    display: flex;
    flex: 1;
    margin-bottom: 0;
    justify-content: center;

    /** add padded spaces between columns, but on last one want padding on left so width the same */
    padding-right: ${({ addGap }) => (addGap ? `16px` : 0)};
    padding-left: ${({ addGap }) => (addGap ? 0 : `16px`)};
  }
`;
