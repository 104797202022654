import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { palette } from '../../styles/palette';
import typographyPlugin from '../../styles/typographyPlugin';

export const Hamburger = styled.img`
  width: 32px;
  &:hover {
    cursor: pointer;
  }
`;

/** the "X" when active is rendered on top of the menu positioned differently. Match positioning of hamburger */
export const Close = styled.img`
  width: 32px;
  opacity: 0.8;
  position: absolute;
  top: 20px;
  right: 15px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    right: 30px;
  }
`;

export const Menu = styled.nav<{ show: boolean }>`
  background: ${colors.primary.default};
  bottom: 0;
  right: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.desktop}) {
    box-shadow: -3px 5px 6px 0 rgba(0, 0, 0, 0.1);
    max-width: 500px;
  }

  &.animate-enter {
    right: -100%;
  }

  &.animate-enter-active {
    right: 0;
    transition: all 200ms;
  }

  &.animate-enter-done {
    right: 0;
  }

  &.animate-exit {
    right: 0;
  }

  &.animate-exit-active {
    right: -100%;
    transition: all 200ms;
  }

  &.animate-exit-done {
    right: -100%;
  }
`;

const linkStyle = css`
  display: block;
  font-size: 30px;
  font-family: ${typographyPlugin.options.headerFontFamily?.join(',')};
  padding-bottom: 40px;
  text-decoration: none;
  &:hover {
    color: ${palette.white};
    text-decoration: none;
  }
`;

export const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const MenuLink = styled(Link)`
  ${linkStyle}
`;

export const DesktopOnlyExternalLink = styled.a`
  ${linkStyle}
  display:none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`;
