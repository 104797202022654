import { createGlobalStyle } from 'styled-components';
import poppinsSemiboldEot from '../assets/fonts/poppins-v20-latin-600.eot';
import poppinsSemiboldTtf from '../assets/fonts/poppins-v20-latin-600.ttf';
import poppinsSemiboldWoff from '../assets/fonts/poppins-v20-latin-600.woff';
import poppinsSemiboldWoff2 from '../assets/fonts/poppins-v20-latin-600.woff2';
import poppinsRegularEot from '../assets/fonts/poppins-v20-latin-regular.eot';
import poppinsRegularTtf from '../assets/fonts/poppins-v20-latin-regular.ttf';
import poppinsRegularWoff from '../assets/fonts/poppins-v20-latin-regular.woff';
import poppinsRegularWoff2 from '../assets/fonts/poppins-v20-latin-regular.woff2';
import { colors } from './colors';
import { palette } from './palette';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: poppins-regular;
    src: url(${poppinsRegularTtf}) format("truetype");
    src: url(${poppinsRegularEot}) format("embedded-opentype");
    src: url(${poppinsRegularWoff}) format("woff");
    src: url(${poppinsRegularWoff2}) format("woff2");
  }

  @font-face {
    font-family: poppins-semibold;
    src: url(${poppinsSemiboldTtf}) format("truetype");
    src: url(${poppinsSemiboldEot}) format("embedded-opentype");
    src: url(${poppinsSemiboldWoff}) format("woff");
    src: url(${poppinsSemiboldWoff2}) format("woff2");
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  a {
    color: ${palette.black};
    text-decoration: underline;

    &:hover {
      color: ${colors.text.light};
      text-decoration: underline;
    }
  }

  button{
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline-color: ${palette.lightGrey}
    }
  }

  /* for youtube iframe embeds via gatsby-remark-embed-video. Make iframe fill width and responsive. https://css-tricks.com/fluid-width-video/ */
  .embedVideo-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .embedVideo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /** https://github.com/KyleAMathews/typography.js/issues/75 for typography plugin for now this sets a larger baseFontSize for desktop */
  @media (min-width: 1024px) {
    html {
      font-size: 150%; /* --> 24px base size not 20px */
    }
  }

`;
