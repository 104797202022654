import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

/**
 * Common side margin and max-width for the content of a section
 *
 * @param root0
 * @param root0.isSmallMaxWidth some pages with lots of text (eg privacy policy need a smaller max width of around 900px)
 * @param root0.isCentre will centre text, used on home page first section
 */
export const SectionContent = styled.div<{
  isCentre?: boolean;
  isSmallMaxWidth?: boolean;
}>`
  padding-left: 32px;
  padding-right: 32px;
  text-align: ${({ isCentre }) => (isCentre ? 'center' : 'left')};
  max-width: ${({ isSmallMaxWidth }) => (isSmallMaxWidth ? '940px' : '1400px')};
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;
