import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import closeIcon from '../../assets/icons/close-icon.png';
import hamburgerIcon from '../../assets/icons/hamburger-icon.svg';
import upsideLogo from '../../assets/images/upside-logo-charlie.png';
import { getClientGatsbyEnv } from '../../config';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Close, Hamburger, Menu, MenuLink, MenuLinks } from './Menu';
import { NavBar } from './NavBar';

export type MenuLink = {
  name: string;
  url: string;
};

export type Props = {
  menuLinks: MenuLink[];
};

const config = getClientGatsbyEnv();

export const Header: React.FC<Props> = ({ menuLinks }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLElement>(null);
  const hamburgerRef = useRef<HTMLImageElement>(null);
  const closeRef = useRef<HTMLImageElement>(null);

  useOnClickOutside(menuRef, (event) => {
    // dont listen to outside clicks on hamburger/close itself as they have their own handler
    if (
      isMenuOpen &&
      !hamburgerRef.current?.contains(event.target as Node) &&
      !closeRef.current?.contains(event.target as Node)
    ) {
      setIsMenuOpen(false);
    }
  });

  return (
    <NavBar>
      <a href={config.MARKETING_SITE_URL}>
        <LogoImage src={upsideLogo} />
      </a>

      <Hamburger
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        ref={hamburgerRef}
        src={hamburgerIcon}
        data-testid="hamburger"
      />

      <CSSTransition in={isMenuOpen} classNames="animate" timeout={200}>
        <Menu show={isMenuOpen} ref={menuRef}>
          <>
            <Close onClick={() => setIsMenuOpen(!isMenuOpen)} ref={closeRef} src={closeIcon} />
            <MenuLinks>
              <MenuLink to={config.MARKETING_SITE_URL}>Home</MenuLink>
              {menuLinks &&
                menuLinks.map((menuLink) => (
                  <MenuLink key={menuLink.url} to={menuLink.url}>
                    {menuLink.name}
                  </MenuLink>
                ))}
            </MenuLinks>
          </>
        </Menu>
      </CSSTransition>
    </NavBar>
  );
};

const LogoImage = styled.img`
  max-width: 180px;
  margin-top: 11px; /* tweak vertical centring */
`;
