import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { palette } from '../../styles/palette';
import { ButtonLink } from '../Button';

type Props = {
  /** Optional button link to right of title */
  buttonLink?: {
    text: string;
    url: string;
  };
  /** the title text */
  children: React.ReactNode;
  /* centre the header */
  isCentred?: boolean;
  /** optionally set as bigger h1 and text colour black (primary), or h2 black (secondary) */
  variant?: 'primary' | 'secondary';
  /** optionally style header */
  style?: React.CSSProperties;
};

/**
 * h2 Section Title with optional button link to the right
 */
export const SectionTitle: React.FC<Props> = ({ children, buttonLink, isCentred, variant, style }) => (
  <FlexWrapper isCentred={isCentred}>
    {variant === 'primary' && <PrimaryHeader style={style}>{children}</PrimaryHeader>}
    {variant === 'secondary' && <SecondaryHeader style={style}>{children}</SecondaryHeader>}
    {!variant && <Header style={style}>{children}</Header>}

    {buttonLink && <StyledButton to={buttonLink.url}>{buttonLink.text}</StyledButton>}
  </FlexWrapper>
);

const StyledButton = styled(ButtonLink)`
  margin-top: -16px;
  margin-bottom: 32px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    /* line up button to right of header */
    margin-left: 24px;
    margin-bottom: 0;
    margin-top: -3px;
  }
`;

const Header = styled.h2`
  display: block;
  color: ${colors.text.default};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: inline-block;
  }
`;

const SecondaryHeader = styled.h2`
  display: block;
  color: ${palette.black};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: inline-block;
  }
`;

const PrimaryHeader = styled.h1`
  display: block;
  color: ${palette.black};
  padding-top: 2rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: inline-block;
  }
`;

const FlexWrapper = styled.div<{ isCentred?: boolean }>`
  /** column on mobile, button below header */
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.desktop}) {
    /** row on desktop, button to right of title */
    flex-direction: row;
    justify-content: ${({ isCentred }) => (isCentred ? 'center' : 'unset')};
  }
`;
