import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { palette } from '../../styles/palette';

/** common style for anchor (a), button and gatsby link */
const style = css`
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  border-width: 2px;
  border-radius: 25px;
  border-color: ${palette.black};
  border-style: solid;
  background-color: transparent;
  color: ${palette.black};
  text-decoration: none;
  min-width: 100px;

  /** don't stretch if placed inside a flexbox */
  align-self: flex-start;

  &:hover,
  &:active,
  &:focus {
    background-color: ${palette.darkCream};
    text-decoration: none;
  }

  &:disabled {
    background: ${palette.lightMidGrey};
  }

  &:link,
  &:visited {
    color: ${palette.black};
    &:hover {
      background-color: ${palette.darkCream};
    }
  }
`;

/** HTML Button */
export const Button = styled.button`
  ${style}
`;

/** Gatsby Link styled the same as Button */
export const ButtonLink = styled(Link)`
  ${style}
`;

/** HTML Anchor (a) styled the same as Button */

export const ButtonAnchor = styled.a`
  ${style}
`;
