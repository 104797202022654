import { graphql, useStaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import styled from 'styled-components';
import { getStaticLink } from '../../constants/urls';
import { PageQuery } from '../../generated/graphqlTypes';
import { fixFixed } from '../../helpers/image';
import { GlobalStyle } from '../../styles/GlobalStyle';
import { NAV_BAR_HEIGHT } from '../../styles/variables';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { ErrorFallback } from '../ErrorFallback';
import { Footer } from '../Footer';
import { Header, MenuLink } from '../Header';

export type Props = {
  children: React.ReactNode;
};

/**
 * a Page with a NavBar Header and a Footer
 *
 * Note: React Helmet only allows direct children and no React fragments else you get a console warn and it doesnt work
 *
 * @param root0
 * @param root0.children
 */
export const Page: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery<PageQuery>(query);
  const menuLinks: MenuLink[] = data.allDatoCmsStatic.edges.map((e) => ({
    name: e.node.title ?? '',
    url: getStaticLink(e.node.slug),
  }));

  return (
    <Wrapper>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetDatoCms favicon={data.datoCmsSite?.faviconMetaTags}>
          <title>{data.datoCmsSite?.globalSeo?.siteName}</title>
          <meta name="facebook-domain-verification" content="arr320gzhkj2sfwls1201x9wqc73sw" />
          {process.env.NODE_ENV !== 'development' && (
            <script type="text/javascript" data-testid="linkedin-script-vars">{`
              _linkedin_partner_id = '3441585';
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}</script>
          )}
          {process.env.NODE_ENV !== 'development' && (
            <script type="text/javascript" data-testid="linkedin-script">{`
                (function(){var s = document.getElementsByTagName('script')[0];
                var b = document.createElement('script');
                b.type = 'text/javascript';b.async = true;
                b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                s.parentNode.insertBefore(b, s);})();
          `}</script>
          )}
          {process.env.NODE_ENV !== 'development' && (
            <noscript data-testid="linkedin-noscript">{`
                <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=3441585&fmt=gif"
                />
          `}</noscript>
          )}
        </HelmetDatoCms>
        <Header menuLinks={menuLinks} />
        <Content>{children}</Content>
        <Footer
          appleAppStoreButton={fixFixed(data.datoCmsHome?.appleAppStoreButton?.fixed)}
          appleAppStoreLink={data.datoCmsHome?.appleAppStoreLink}
          googlePlayStoreButton={fixFixed(data.datoCmsHome?.googlePlayStoreButton?.fixed)}
          googlePlayStoreLink={data.datoCmsHome?.googlePlayStoreLink}
          mobileWebAppButton={fixFixed(data.datoCmsHome?.mobileWebAppButton?.fixed)}
        />
      </ErrorBoundary>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: ${NAV_BAR_HEIGHT}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const query = graphql`
  query Page {
    datoCmsSite {
      globalSeo {
        siteName
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    allDatoCmsStatic(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    datoCmsHome {
      appleAppStoreButton {
        fixed(width: 110, height: 33, imgixParams: { fit: "crop", w: "110", h: "33" }) {
          ...GatsbyDatoCmsFixed
        }
      }
      appleAppStoreLink
      googlePlayStoreButton {
        fixed(width: 110, height: 33, imgixParams: { fit: "crop", w: "110", h: "33" }) {
          ...GatsbyDatoCmsFixed
        }
      }
      googlePlayStoreLink
      mobileWebAppButton {
        fixed(width: 110, height: 33, imgixParams: { fit: "crop", w: "110", h: "33" }) {
          ...GatsbyDatoCmsFixed
        }
      }
    }
  }
`;
