import React from 'react';
import { ErrorContainer, HelperList, PageContent } from './ErrorFallback.styled';

export const ErrorFallback: React.FC = () => {
  return (
    <ErrorContainer>
      <PageContent>
        <h1>Sorry, this is not working properly.</h1>
        <p>In the meantime, here is what you can do:</p>
        <HelperList>
          <li>Refresh the page (Sometimes it helps).</li>
          <li>Try again in 30 minutes.</li>
          <li>
            <a href="mailto:help@upsidetechnology.co">Tell us what happened.</a>
          </li>
        </HelperList>
      </PageContent>
    </ErrorContainer>
  );
};
