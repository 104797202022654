import queryString from 'query-string';
import { getClientGatsbyEnv } from '../config';

const config = getClientGatsbyEnv();

export const getBlogLink = (slug?: string | null): string => {
  if (!slug) throw new Error('empty blog slug');
  return `/blog/${slug}`;
};

export const getStaticLink = (slug?: string | null): string => {
  if (!slug) throw new Error('empty static slug');
  return `/${slug}`;
};

/** create mobile app link passing through utm params if they are in query params (use `useLocation` and pass in `location.search` */
export const getMobileWebAppLink = (args: { urlParams?: string | null; fallbackUtmCampaign?: string | null }) => {
  const { urlParams, fallbackUtmCampaign } = args;
  const { utm_source, utm_medium, utm_campaign } = queryString.parse(urlParams ?? '');

  const final_utm_source = utm_source && typeof utm_source === 'string' ? utm_source : `www`;
  const final_utm_medium = utm_medium && typeof utm_medium === 'string' ? utm_medium : `web`;
  const final_utm_campaign =
    utm_campaign && typeof utm_campaign === 'string' ? utm_campaign : fallbackUtmCampaign ?? `defaultWebCampaign`;

  return `${config.GATSBY_MOBILE_APP_URL}/Landing/?utm_source=${final_utm_source}&utm_medium=${final_utm_medium}&utm_campaign=${final_utm_campaign}`;
};

/**
 * Returns correct link to apple app store from this logic
 * 1. if URL param `branch=xyz123` exists construct a branch link with that alias (use `useLocation` and pass in `location.search`)
 * 2. if link function argument is truthy use that
 * 3. fallback to direct link to app store
 */
export const getAppleAppStoreLink = (args: { urlParams?: string | null; linkUrl?: string | null }) => {
  const { urlParams, linkUrl } = args;
  const { branch } = queryString.parse(urlParams ?? '');
  if (branch && typeof branch === 'string') return `https://upsidetechnology.app.link/${branch}`;
  if (linkUrl) return linkUrl;
  return `https://apps.apple.com/us/app/upside-smarter-stock-insights/id1589247990`;
};

/**
 * Returns correct link to google play store from this logic
 * 1. if URL param `branch=xyz123` exists construct a branch link with that alias (use `useLocation` and pass in `location.search`)
 * 2. if link function argument is truthy use that
 * 3. fallback to direct link to app store
 */
export const getGooglePlayStoreLink = (args: { urlParams?: string | null; linkUrl?: string | null }) => {
  const { urlParams, linkUrl } = args;
  const { branch } = queryString.parse(urlParams ?? '');
  if (branch && typeof branch === 'string') return `https://upsidetechnology.app.link/${branch}`;
  if (linkUrl) return linkUrl;
  return `https://play.google.com/store/apps/details?id=co.upsidetechnology.mobile`;
};

export type UTMParams = { utmSource: string; utmMedium: string; utmCampaign: string };

/** read utm params to pass on to mailchimp waiting list sign up */
export const getMailchimpUtmParams = (args: { urlParams?: string | null }): UTMParams => {
  const { urlParams } = args;
  const { utm_source, utm_medium, utm_campaign } = queryString.parse(urlParams ?? '');
  if (typeof utm_source === 'string' && typeof utm_medium === 'string' && typeof utm_campaign === 'string') {
    return { utmSource: utm_source, utmMedium: utm_medium, utmCampaign: utm_campaign };
  }
  return { utmSource: '', utmMedium: '', utmCampaign: '' };
};

/** read query params and return true if ?appStore=true */
export const showAppStoreButtons = (args: { urlParams?: string | null }): boolean => {
  const { urlParams } = args;
  const { appStore } = queryString.parse(urlParams ?? '');
  if (typeof appStore === 'string' && appStore === 'true') {
    return true;
  }
  return false;
};
