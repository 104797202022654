import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageContent = styled.div`
  padding: 8px;
`;

export const HelperList = styled.ul`
  list-style: disc inside;
  margin-top: 8px;
`;
