import styled from 'styled-components';

export const SectionContainer = styled.section<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor};
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;

  /** remove padding on last paragraph of text from dato to just use this container padding */
  * + p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
