import * as Sentry from '@sentry/browser';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  FallbackComponent: React.ComponentType;
}
interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    console.error(error);
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { FallbackComponent, children } = this.props;
    return hasError ? <FallbackComponent /> : children;
  }
}
