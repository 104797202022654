import { RefObject, useEffect } from 'react';

/**
 * Usage: `useOnClickOutside(ref, () => setModalOpen(false));`
 *
 * @param ref pass in ref to element you want to detect click outside of (ie ref to the hamburger menu)
 * @param handler runs this handler on every click outside of ref (eg to close menu).
 *                Optional: pass in `handler` wrapped in a useCallback to avoid re-running
 *                this effect every render. A very minor speed up :)
 */
export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        // a click inside (ie ref or child of ref), so do nothing
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }),
    [ref, handler];
};
